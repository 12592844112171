import {Fab, Typography} from "@mui/material";
import { ComponentProps, useContext, useState} from "react"
import { PieChart } from 'react-minimal-pie-chart';
import { ExtendedDataEntry } from "react-minimal-pie-chart/types/commonTypes";
import { CrawlerContext } from "../common/crawler-context";
import { RoadInfo } from "../common/utils";
import PlayIcon from "@mui/icons-material/PlayArrow";


const LABELS = ["Nature", "Traffic", "Public Transport"]
const COLORS = ["#009900", "#FF0000", "#FFA500"]

export default function SoundChart() {
    
  const [hovered, setHovered] = useState<number>(0);
  
  const statsContext = useContext(CrawlerContext)

  const absoluteValues = [
    (statsContext.stats.nature) ? statsContext.stats.nature.volume : 0,
    (statsContext.stats.traffic) ? statsContext.stats.traffic.volume : 0,
    (statsContext.stats.pt) ? statsContext.stats.pt.volume : 0
  ]

  const totalValue=absoluteValues[0]+absoluteValues[1]+absoluteValues[2]

const data: ComponentProps<typeof PieChart>['data'] = [
    {color: COLORS[0], value: 100*absoluteValues[0]/totalValue, title: LABELS[0], key: 0},
    {color: COLORS[1], value: 100*absoluteValues[1]/totalValue, title: LABELS[1], key: 1},
    {color: COLORS[2], value: 100*absoluteValues[2]/totalValue, title: LABELS[2], key: 2},
]


const getNatureInfoElement = (name: String, value: number) => {
  if (!value)
    return null
  const rounded = Math.round(100*value)
  const valText = (rounded > 0) ? rounded : "< 1"
  return (<Typography variant="body1"> {valText}% {name}</Typography>) 
}
  
  function getNatureInfo() {
    if (!statsContext.stats.nature)
      return (null)
    return (<>
    <Typography variant="subtitle1"> Ratio of total area</Typography>
    {getNatureInfoElement("forest", statsContext.stats.nature.data.forestArea)}
    {getNatureInfoElement("park", statsContext.stats.nature.data.parkArea)}
    {getNatureInfoElement("meadow", statsContext.stats.nature.data.meadowArea)}
    {getNatureInfoElement("lake", statsContext.stats.nature.data.lakeArea)}
    {getNatureInfoElement("river (shipping)", statsContext.stats.nature.data.riverLargeArea)}
    {getNatureInfoElement("river (no shipping)", statsContext.stats.nature.data.riverSmallArea)}
    </>);
  }
  function getTrafficInfoElement(name: String, info: RoadInfo) {
    if (!info.length)
      return null
    const jamLengthRatio = info.jamInfoLength / info.length
    let lengthText = "some"
    if (jamLengthRatio > 0.75)
      lengthText = "most of the"
    else if (jamLengthRatio > 0.5)
        lengthText = "many"
        
    let intensityText = "Low"
    if (info.jamAvg > 6.5)
      intensityText = "High"
    else if (info.jamAvg > 3.5)
      intensityText = "Medium"
    return (<><Typography variant="body1"> {Math.round(100*info.length/1000)/100}km {name}</Typography>
    { jamLengthRatio > 0.05 ? (<>
      <Typography variant="body2"> {intensityText} congestion on {lengthText} streets</Typography></>) : null }</>) 
  }

  function getTrafficInfo() {
    if (!statsContext.stats.traffic)
      return (null)
    return (<>
      {getTrafficInfoElement("Motorways", statsContext.stats.traffic.data.motorwayInfo)}
      {getTrafficInfoElement("Outside Cities", statsContext.stats.traffic.data.tertiaryInfo)}
      {getTrafficInfoElement("Inside Cities", statsContext.stats.traffic.data.roadInfo)}
      {getTrafficInfoElement("Residential", statsContext.stats.traffic.data.residentialInfo)}
      </>);
  }


  function getPtInfoElement(name: String, value: number) {
    if (!value)
      return null
    return (<Typography variant="body1"> {Math.round(10*value)/10 + " " + name}</Typography>) 
  }

  function getPtInfo() {
    if (!statsContext.stats.pt)
      return (null)
    return (<>
    <Typography variant="subtitle1"> Vehicles per minute</Typography>
      {getPtInfoElement("busses", statsContext.stats.pt.data.busses)}
      {getPtInfoElement("trams", statsContext.stats.pt.data.trams)}
      {getPtInfoElement("suburban trains", statsContext.stats.pt.data.suburbans)}
      {getPtInfoElement("regional trains", statsContext.stats.pt.data.regionals)}
      {getPtInfoElement("express trains", statsContext.stats.pt.data.express)}
      {getPtInfoElement("ferries", statsContext.stats.pt.data.ferries)}
    </>);
  }

  function getInfo() {
    if (hovered == 0)
      return getNatureInfo()
    else if (hovered == 1)
      return getTrafficInfo()
    return getPtInfo()
  }
  

  function currentLabel(dataEntry: ExtendedDataEntry) {
    if (dataEntry.key! > 0)
      return ""
    return (
      <>
      <text
        x={50}
        y={45}
        dx={0}
        dy={0}
        dominant-baseline="central"
        text-anchor="middle"
        style={{
          fontSize: '15px',
          fontFamily: 'sans-serif',
          fill: COLORS[hovered],
        }}
      >
        {Math.round(data[hovered].value) + '%'}
      </text>
      <text
      x={50}
      y={55}
      dx={0}
      dy={0}
      dominant-baseline="central"
      text-anchor="middle"
      style={{
        fontSize: '9px',
        fontFamily: 'sans-serif',
        fill: COLORS[hovered],
      }}
    >
      {LABELS[hovered]}
    </text></>
  );
  }

    return (
      <>
        { totalValue > 0 ? 
        (<>
          <p>
            Here you can see the actual distribution of sound sources calculated by Bird's Ear for this area in the moment.
          </p>
          <PieChart
              data={data}
              radius={PieChart.defaultProps.radius - 6}
              lineWidth={20}
              paddingAngle={5}
              label={({ x, y, dx, dy, dataEntry }) => currentLabel(dataEntry)}
              labelPosition={0}
              labelStyle={(index) => ({
                  fill: COLORS[hovered],
                  fontSize: '10px',
                  fontFamily: 'sans-serif',
                })}
              onMouseOver={(_, index) => {
                  setHovered(index);
              }}
              /> 
            {getInfo()}
        </>) : (<Typography variant="h6" color="primary" sx={{marginTop: 1}}><em>Nothing playing. Click
              <Fab color="primary" size="small"
                   sx={{position: 'relative!important' as any, verticalAlign: 'middle'}}
              onClick={statsContext.startPlaying}>
                <PlayIcon/>
              </Fab> to start!</em></Typography>)
          }
          </>
    )
}
