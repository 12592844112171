import React from "react"

import {LatLngBounds, LatLngTuple, Point} from "leaflet"

import {Entity} from "./EntityStore"
import {EntityType} from "./Entities"
import {SpatialTuple} from "../map/Map";
import {GroupStats} from "./utils"

export const EntitySonified = React.createContext(false)
export const EntityVisualized = React.createContext(false)

export const CrawlerContext = React.createContext<CrawlerContextType>({
    entities: {},
    stats: {nature: undefined, traffic: undefined, pt: undefined},
    startPlaying: () => { }
})

export interface CrawlerContextType {
    entities: Record<string, Entity>,
    stats: GroupStats,
    startPlaying: () => void
}

export const MapContext = React.createContext<MapContextType>({
    checkInBounds: () => false,
    calculatePositionInBounds: () => [0, 0, 0],
    calculateAreaCoverageInBounds: () => 0,
    calculateMetricAreaCoverageInBounds: () => 0,
    calculatePointInBounds: () => new Point(-1000, -1000),

    bounds: undefined
})

export interface MapContextType {
    calculateMetricAreaCoverageInBounds(size: number): number;
    calculatePointInBounds: (point: LatLngTuple) => Point,

    checkInBounds: (point: LatLngTuple) => boolean,
    calculatePositionInBounds: (point: LatLngTuple) => SpatialTuple,
    calculateAreaCoverageInBounds: (areaSize: number) => number,
    bounds: LatLngBounds | undefined
}

export interface CrawlerProps {
    bounds: LatLngBounds | undefined,
    addOrUpdateEntity: (id: string, data: any, entityType: EntityType) => void
}
