import React, {createRef} from 'react'

import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Snackbar
} from "@mui/material"
import {LatLng, LatLngBounds} from "leaflet"
import {w3cwebsocket as W3CWebSocket} from "websocket";

import './App.scss'
import Map, {MapRef} from "./map/Map"
import {CrawlerContext, EntitySonified, EntityVisualized} from "./common/crawler-context";
import {Entity} from "./common/EntityStore";
import {EntityType} from "./common/Entities";
import {OVERLAYS_ON} from "./common/constants";
import Controls from "./ui/Controls";
import SideBar from './sideBar/sideBar';
import { GroupStats } from './common/utils';


interface AppState {
    bounds: LatLngBounds | undefined,
    zoom: number | undefined,

    loading: boolean,
    loadingState: LoadingState,
    playing: boolean,
    overlays: boolean,
    entities: Record<string, Entity>,
    stats: GroupStats,
    showError: boolean,
    errorMessage: string,
    errorAction: JSX.Element | null,

    scenario: number
    ptFrequency: number
    trafficFrequency: number
}

class LoadingState {
    bvg: boolean = false
    osm: boolean = false
    here: boolean = false

    isLoaded(): boolean {
        return this.bvg && this.osm && this.here;
    }

    seenBvg(): LoadingState {
        let copy = Object.assign(Object.create(Object.getPrototypeOf(this)), this)
        copy.bvg = true;
        return copy
    }

    seenOSM(): LoadingState {
        let copy = Object.assign(Object.create(Object.getPrototypeOf(this)), this)
        copy.osm = true;
        return copy
    }

    seenHERE(): LoadingState {
        let copy = Object.assign(Object.create(Object.getPrototypeOf(this)), this)
        copy.here = true;
        return copy
    }
}

const WS_ADDRESS = (process.env.NODE_ENV === 'production') ? 'wss://birds-ear.de' : 'ws://localhost:8999';
let client = new W3CWebSocket(WS_ADDRESS);

class App extends React.Component<{}, AppState> {
    private mapRef: React.RefObject<MapRef>
    private updateTimer: NodeJS.Timeout | undefined = undefined

    constructor(props: any) {
        super(props)

        this.mapRef = createRef<MapRef>();

        this.state = {
            bounds: undefined,
            zoom: undefined,
            loading: false,
            loadingState: new LoadingState(),
            playing: false,
            overlays: OVERLAYS_ON,
            entities: {},
            stats: {nature: undefined, traffic: undefined, pt: undefined},
            showError: false,
            errorMessage: "",
            errorAction: null,

            scenario: 1,
            ptFrequency: 100,
            trafficFrequency: 100
        }
        this.startPlaying = this.startPlaying.bind(this)
        this.togglePlaying = this.togglePlaying.bind(this)
        this.toggleOverlays = this.toggleOverlays.bind(this)
        this.onMapBoundsUpdate = this.onMapBoundsUpdate.bind(this)
        this.retryConnection = this.retryConnection.bind(this)
        this.navigateToLocation = this.navigateToLocation.bind(this)
        this.navigateToBounds = this.navigateToBounds.bind(this)
        this.onScenarioUpdate = this.onScenarioUpdate.bind(this)
    }

    componentWillMount() {
        this.connect()
    }

    connect() {
        client = new W3CWebSocket(WS_ADDRESS)
        client.onopen = () => {
            if (this.state.playing) {
                this.requestNewBounds()
            }
        }
        client.onerror = (error: Error) => {
            this.setState({
                showError: true,
                errorMessage: "Connection to WebSocket failed. Is the server running?",
                errorAction:
                    (<Button color="secondary" size="small" onClick={this.retryConnection}>
                        Retry
                    </Button>)
            })
        }
        client.onclose = () => {
            this.setState({
                showError: true,
                errorMessage: "Connection to WebSocket lost. Is the server running?",
                errorAction:
                    (<Button color="secondary" size="small" onClick={this.retryConnection}>
                        Retry
                    </Button>)
            })
        }
        client.onmessage = (message: any) => {
            const dataFromServer = JSON.parse(message.data)

            // do not accept new data if currently not playing or loading (e.g. when clicking stop and then not interacting)
            if(this.state.playing || !this.state.loadingState.isLoaded()) {
                const entities = dataFromServer.entities as Record<string, Entity>

                const newStats = this.state.stats

                let newLoadingState: LoadingState | null = null
                let entityTypes: EntityType[] = []
                switch (dataFromServer.type) {
                    case "OSM": {
                        entityTypes = [EntityType.Area, EntityType.Road]
                        newLoadingState = this.state.loadingState.seenOSM()
                        newStats.nature = dataFromServer.info
                        break
                    }
                    case "BVG": {
                        entityTypes = [EntityType.BVG]
                        newLoadingState = this.state.loadingState.seenBvg()
                        newStats.pt = dataFromServer.info
                        break
                    }
                    case "HERE": {
                        entityTypes = [EntityType.TrafficGridCell]
                        newLoadingState = this.state.loadingState.seenHERE()
                        newStats.traffic = dataFromServer.info
                        break
                    }
                }

                if (newLoadingState) {
                    const oldEntities = Object.fromEntries(Object.entries(this.state.entities).filter(([entityId, entity]) => {
                        return !entityTypes.includes(entity.type) || entityId in entities
                    }))
                    this.setState({
                        loading: !newLoadingState.isLoaded(),
                        loadingState: newLoadingState,
                        entities: {...oldEntities, ...entities},
                        stats: newStats
                    })
                    if (newLoadingState.isLoaded()) {
                        this.setState({
                            playing: true
                        })
                    }
                }
            }
        }
    }

    retryConnection() {
        this.setState({
            showError: false
        })
        this.connect()
    }

    requestNewBounds() {
        const bounds = this.state.bounds
        if (bounds) {
            if (client.readyState === W3CWebSocket.OPEN) {
                client.send(JSON.stringify({
                    type: "NEW_BOUNDS",
                    bounds: [[bounds.getSouthWest().lat, bounds.getSouthWest().lng], [bounds.getNorthEast().lat, bounds.getNorthEast().lng]],
                    scenarios: {
                        pt: this.state.ptFrequency,
                        traffic: this.state.trafficFrequency,
                    }
                }))
            }
        }
    }

    onMapBoundsUpdate(bounds: LatLngBounds, zoom: number) {
        if (this.state.playing) {
            this.stopPlaying()
            if (this.updateTimer) clearTimeout(this.updateTimer)
            this.updateTimer = setTimeout(() => {
                this.startPlaying()
            }, 1800)
        }
        this.setState({bounds: bounds, zoom: zoom})
    }

    onScenarioUpdate(scenario: number, ptFrequency: number, trafficFrequency: number) {
        const wasPlaying = this.state.playing
        this.setState({scenario: scenario, ptFrequency: ptFrequency, trafficFrequency: trafficFrequency})
        if (wasPlaying) {
            this.setState({entities: {}, playing: false, stats: {nature:undefined, traffic:undefined, pt:undefined}})
        }
        if(wasPlaying) {
            this.startPlaying()
        }
    }

    togglePlaying() {
        if (this.state.playing) {
            this.stopPlaying()
        } else {
            this.startPlaying()
        }
    }

    stopPlaying() {
        this.setState({entities: {}, playing: false, stats: {nature:undefined, traffic:undefined, pt:undefined}})
    }

    startPlaying() {
        this.setState({playing: false, loading: true, loadingState: new LoadingState()})
        this.requestNewBounds()
    }

    toggleOverlays() {
        this.setState({overlays: !this.state.overlays})
    }

    navigateToLocation(center: LatLng, zoom: number) {
        const wasPlaying = this.state.playing
        if  (wasPlaying) {
            this.setState({entities: {}, playing: false, stats: {nature:undefined, traffic:undefined, pt:undefined}})
        }
        this.mapRef.current?.flyTo(center, zoom)
        this.startPlaying()
        if(wasPlaying) {
            this.startPlaying()
        }
    }

    navigateToBounds(bounds: LatLngBounds) {
        const wasPlaying = this.state.playing
        if (wasPlaying) {
            this.setState({entities: {}, playing: false, stats: {nature:undefined, traffic:undefined, pt:undefined}})
        }
        this.mapRef.current?.flyToBounds(bounds)
        if(wasPlaying) {
            this.startPlaying()
        }
    }

    render() {
        return (
            <>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={this.state.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

                <CrawlerContext.Provider value={{entities: this.state.entities, stats: this.state.stats, startPlaying: this.startPlaying}}>
                    <EntitySonified.Provider value={this.state.playing}>
                        <EntityVisualized.Provider value={this.state.overlays}>
                            <Map onBoundsUpdate={this.onMapBoundsUpdate}
                                 ref={this.mapRef}/>
                        </EntityVisualized.Provider>
                    </EntitySonified.Provider>
                    <SideBar
                        mapBounds={this.state.bounds}
                        mapZoom={this.state.zoom}
                        navigateToLocation={this.navigateToLocation}
                        scenario={this.state.scenario}
                        ptFrequency={this.state.ptFrequency}
                        trafficFrequency={this.state.trafficFrequency}
                        onScenarioUpdate={this.onScenarioUpdate}/>
                </CrawlerContext.Provider>

                <Controls
                    playing={this.state.playing}
                    togglePlaying={this.togglePlaying}
                    navigateToBounds={this.navigateToBounds}
                    scenario={this.state.scenario}/>

                <Snackbar open={this.state.showError}
                          sx={{padding: 0}}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                        {this.state.errorAction}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

export default App
