import React, {useContext} from "react"

import TrafficSoundComposition from "./TrafficSoundComposition";
import {EntitySonified, MapContext} from "../common/crawler-context";
import {Entity} from "../common/EntityStore";


const TrafficGridCell = React.memo(function TrafficGridCell(props: Entity) {
    const entitySonified = useContext(EntitySonified)

    const mapContext = useContext(MapContext)
    const spatialPosition = mapContext.calculatePositionInBounds(props.data.centroid)

    return (
        <>
            <TrafficSoundComposition area={props.data.area}
                                     playing={entitySonified}
                                     spatialPosition={spatialPosition}
                                     roadTypeInfos={props.data.roadTypeInfos}/>
        </>
    )
})

export default TrafficGridCell
