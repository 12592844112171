import {LatLngBounds} from "leaflet"

const GEOAPIFY_URL = "https://api.geoapify.com/v1/geocode/search?"
const GEOAPIFY_API_KEY = "b891b122e4ec4f78b126edbd588018fc"

class LocationSearch {
    onLatLngFound: (bounds: LatLngBounds) => void

    constructor(onLatLngFound: (bounds: LatLngBounds) => void) {
        this.onLatLngFound = onLatLngFound
    }

    searchForText(text: string) {
        return new Promise<any>((resolve, reject) => {
            const url = `${GEOAPIFY_URL}text=${text}&apiKey=${GEOAPIFY_API_KEY}`;
            fetch(url, {method: 'GET'})
                .then((response: Response) => response.json())
                .then((data: any) => this.onDataReceive(data))
        })
    }

    onDataReceive(data: any) {
        const bounds = data.features[0].bbox
        this.onLatLngFound(new LatLngBounds([bounds[1], bounds[0]], [bounds[3], bounds[2]]))
    }
}

export default LocationSearch
