import { ROAD_TYPE } from "./utils"

export type sound = {
    file: string
    licence: string
    source: string
    description?: string 
    duration: number
}

export type taggedSound = {
    sound: sound
    tags: Record<string,string>
}


function createBBCSound(file: string, duration: number,tags: Record<string, string>, id: string, description?: string) : taggedSound {
    return {
        sound: {
            file: file,
            duration: duration,
            licence: "bbc.co.uk – © copyright 2022 BBC (https://sound-effects.bbcrewind.co.uk/licensing)",
            source: "https://sound-effects.bbcrewind.co.uk/search?q=" + id,
            description: description
        },
        tags: tags
    }
}
function createUnknownSound(file: string, duration: number,tags: Record<string, string>, description?: string) : taggedSound {
    return {
        sound: {
            file: file,
            duration: duration,
            licence: "???",
            source: "???",
            description: description
        },
        tags: tags
    }
}

// TAGs
//all: birds (yes, [no]) people (yes, [no]) wind (medium, strong, [none]) rain(yes, [no]), season (summer autumn fall spring), time (morning midday evening night)
    //rivers: flowType (running, flowing, lapping) 
    //lakes: splashes (yes, [no])


const GREEN_AREA_SOUNDS = {
    "park": 
        [createBBCSound("park_occasionalPeopleAndBirds_bbc_st--james-_07017159", 6*60+35, {"people": "yes", "birds": "yes", "wind": "medium"}, "07017159"),
        createBBCSound("park_walkingPeople_bbc_walk-in-th_00008123", 23, {"people":"yes", "wind":"medium", "birds": "yes"}, "00008123")
        ],
    "forest":
        [createBBCSound("forest_birds1_bbc_montane-fo_nhu0505007", 39, {"birds": "yes", "time": "morning"}, "nhu05050078"),
        createBBCSound("forest_birds_insects_bbc_northern-c_nhu0507401", 2*60+49, {"birds": "yes", "season": "spring"}, "nhu05050078", "many insects")
        ],
    "meadow":
        [createBBCSound("meadow_morning1_bbc_meadow-atm_nhu0501417", 97, {"birds": "yes", "time": "morning"}, "nhu05014174", "sunrise"),
        createBBCSound("meadow_morning2_bbc_meadow-atm_nhu0503902", 119, {"birds": "yes", "time": "morning"}, "nhu05039026", "6 am" )
        ],
    "default": [createUnknownSound("areas/forest", 106, {"birds": "yes", "wind": "medium"})]
    }

    

const WATER_SOUNDS = {
    "river_large":
        [createBBCSound("bbc_slow-flowi_nhu0507404", 249, {"flowType": "flowing", "birds": "yes", "season": "winter", "time": "midday"}, "NHU05074046"),
         createBBCSound("river_thames_bbc_london---l_07060066", 205, {"flowType": "lapping", "wind": "medium"}, "07060066"),
        ],
    "river_small":
        [createBBCSound("bbc_water--sma_07031096", 205, {"flowType":"flowing", "wind": "medium"}, "7031096"),
         createBBCSound("river_small_bbc_mixed---ri_07044052", 119, {"flowType": "running"}, "07044052")
        ],
    "lake":
        [createBBCSound("lake_waterOnShore_Wind_bbc_gentle-lap_nhu0507500", 80, {"wind": "medium", "splashes": "yes"}, "NHU05075002"),
        createBBCSound("lake_birds_bbc_lake---aut_nhu05084112", 172, {"wind": "medium", "splashes": "yes", "birds": "yes"}, "NHU05084112")
        ],
    "default": [createBBCSound("bbc_water--sma_07031096", 205, {"type":"flowing", "wind": "medium"}, "7031096")]
    }

export const AREA_SOUNDS : Record<string, Record<string, taggedSound[]>> = {"water": WATER_SOUNDS, "green_area": GREEN_AREA_SOUNDS}

//Todo: all BVG sounds have to be cutted + tagged
export const BVG_SOUNDS: Record<string, taggedSound[]> = {
    "suburban": 
        [createBBCSound("suburban_train_interior_bbc_trains--el_07041088", 27, {}, "07041088", "interior, contains sound when starting and stopping")
        ],
    "tram":
        [createBBCSound("tram_many_stops_interior_bbc_trams--hun_07062071", 4*60+56, {people: "yes"}, "07062071", "interior, has many stops") // TODO: cut different stops
        ],
    "bus":
        [createUnknownSound("publicTransport/bus", 14, {})
        ],
    "ferry":
        [createBBCSound("ferry_bbc_boats--fer_07034021", 60+51, {}, "07034021")
        ],
    "express":
        [createBBCSound("express_mixdown", 7, {}, "07036089", "french express train passing")
        ],
    "regional":
        [createBBCSound("steam_train_mixdown", 30, {}, "07045065", "steam train passing")
        ],
    "default": [createUnknownSound("publicTransport/bus", 18, {})]
    }

    
//Todo: all traffic sounds have to be cutted + tagged (and we need more)
export const TRAFFIC_SOUNDS = {
    [ROAD_TYPE.MOTORWAY]: [
        createBBCSound("traffic_motorway_busy_bbc_motorways-_07060047", 0, {"season": "winter", "level": "busy"}, "07060047", "1977"),
    createBBCSound("traffic_motorway_busy_bbc_traffic--l_07031001", 0, {level: "busy"}, "07031001", "London")],
    [ROAD_TYPE.TERTIARY]: [
        createBBCSound("traffic_tertiary_normal_bbc_french-tra_07036078", 0, {honking: "yes", level: "busy"}, "07036078", "France"),
        createBBCSound("traffic_tertiary_normal_bbc_rome-traff_07035158", 0, {level: "normal"}, "07035158", "Rome")],
    [ROAD_TYPE.ROAD]: [
        createBBCSound("traffic_road_normal_bbc_traffic---_07031002", 0, {honking: "yes", level: "busy"}, "07031002", "London"),
        createBBCSound("traffic_road_normal_bbc_london---t_07060042", 0, {level: "normal"}, "07060042", "London 1981")],
    [ROAD_TYPE.RESIDENTIAL]: [createBBCSound("traffic_tertiary_quiet_bbc_traffic--m_07060045", 0, {"time": "evening", level: "quite"}, "07060045", "1972"),
    // createBBCSound("traffic_residential_busy_bbc_children--_07058120.mp3", 0, {level: "busy"}, "TODO", "TODO") //TODO change residential sound
    ],
}