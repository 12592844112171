import * as React from 'react';
import Tutorial from "../tutorial/tutorial";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    TextField, Typography,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import PlayIcon from "@mui/icons-material/PlayArrow";
import SearchIcon from "@mui/icons-material/Search";
import {useLocation} from "react-router-dom";
import {LatLngBounds} from "leaflet";
import LocationSearch from "../map/LocationSearch";


interface ControlsProps {
    playing: boolean

    togglePlaying: () => void
    navigateToBounds: (bounds: LatLngBounds) => void

    scenario: number
}

export default function Controls(props: ControlsProps) {

    const [isSearchLocationDialogOpen, setIsSearchLocationDialogOpen] = React.useState(false)

    const [locationSearchText, setLocationSearchText] = React.useState("")

    const searchParams = new URLSearchParams(useLocation().search)
    const isIFrame = searchParams.get('isIFrame') ? searchParams.get('isIFrame') === "true" : false
    const hidePlayButton = searchParams.get('hidePlayButton') ? searchParams.get('hidePlayButton') === "true" : false
    const hideZoomControl = searchParams.get('hideZoomControl') ? searchParams.get('hideZoomControl') === "true" : false
    const toggleSearchLocationDialog = () => {
        setIsSearchLocationDialogOpen(!isSearchLocationDialogOpen)
    }    

    const searchLocation = () => {
        setIsSearchLocationDialogOpen(false)
        locationSearch.searchForText(locationSearchText)
    }
   
    const locationSearch = new LocationSearch((bounds: LatLngBounds) => {
        props.navigateToBounds(bounds)
    })

    const scenarioName = () => {
        if(props.scenario === 0) {
            return "Custom"
        }
        if(props.scenario === 1) {
            return "Actual Soundscape"
        }
        if(props.scenario === 2) {
            return "Car-free city"
        }
        if(props.scenario === 3) {
            return "More individual traffic"
        }
        return "Unkown"
    }

    return (
        <>
            {hidePlayButton ? null : <Fab onClick={props.togglePlaying} color="primary" 
                 sx={{position: 'absolute!important' as any, right: isIFrame ? 16 : 80, bottom: 16}}>
                {props.playing ? <StopIcon/> : <PlayIcon/>}
            </Fab>}
            {!isIFrame ? (<Fab onClick={toggleSearchLocationDialog}  size="small"
                 sx={{position: 'absolute' as any, right: 8, top: 80}}>
                {<SearchIcon/>}
            </Fab>) : null}
            {!isIFrame ? <Tutorial/> : null}

            {props.scenario !== 1 ? (
                <>
                    <Typography variant={"h4"} sx={{position: 'absolute' as any, right: 70, top: 40, color: "red", fontWeight: 800}}>{scenarioName()}</Typography>
                    <Typography variant={"h5"} sx={{position: 'absolute' as any, right: 70, top: 16, color: "red"}}>SCENARIO</Typography>
                </>):
                <Typography variant={"h4"} sx={{position: 'absolute' as any, right: hideZoomControl ? 16 : 70, top: 16}}>LIVE</Typography>
            }

            <Dialog open={isSearchLocationDialogOpen}
                    onClose={toggleSearchLocationDialog}>
                <DialogTitle> Seach for Location </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the location or address you like to go to.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Location Text"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={locationSearchText}
                        onChange={(e) => setLocationSearchText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleSearchLocationDialog}>Cancel</Button>
                    <Button onClick={searchLocation}>Go to Location</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
