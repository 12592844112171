import React, {useContext, useEffect, useRef, useState} from "react"

import ReactHowler from "react-howler"

import {Entity} from "../common/EntityStore"
import {EntitySonified, MapContext} from "../common/crawler-context"
import {hashFnv32a} from "../common/utils"

import {AREA_SOUNDS, taggedSound} from "../common/sounds"



const AreaEntity = React.memo(function AreaEntity(props: Entity) {
    const mapContext = useContext(MapContext)
    const entitySonified = useContext(EntitySonified)

    const category = props.data.category
    const subcategory = props.data.subcategory
    const centroid = props.data.centroid
    const volume = props.data.volume

    const [playing, setPlaying] = useState(false)

    const howlerRef = useRef<ReactHowler>(null)

    const soundFile = () => {
        const soundMap: Record<string, taggedSound[]> = AREA_SOUNDS[category]!!
        if (!soundMap)
            console.error("No soundmap for " + category)

        let sounds = soundMap[subcategory]
        if (!sounds)
            sounds = soundMap["default"]

        return sounds[hashFnv32a(props.id) % sounds.length]
    }

    const calculatePosition = () => {
        if (centroid) {   //checkInBounds can be removed if we do not support dynamic moving any more
            if (mapContext.checkInBounds(centroid)) {
                setPlaying(true)
                const spatialPosition = mapContext.calculatePositionInBounds(centroid)
                howlerRef.current?.howler.pos(spatialPosition[0], spatialPosition[1], spatialPosition[2])
                // @ts-ignore
                howlerRef.current?.howler.orientation(0, 0, 1)
            } else {
                setPlaying(false)
            }
        }
    }

    useEffect(() => {
        calculatePosition()
    }, [centroid])

    return (
        <>
            <ReactHowler
                src={`sounds/${soundFile().sound.file}.mp3`}
                playing={entitySonified && playing}
                volume={volume}
                ref={howlerRef}
                loop={true}
            />
        </>
    )
})

export default AreaEntity
