import React, {useContext, useEffect, useRef, useState} from "react"

import ReactHowler from "react-howler"

import {Entity} from "../common/EntityStore"
import {EntitySonified, MapContext} from "../common/crawler-context"
import {hashFnv32a} from "../common/utils"
import {BVG_SOUNDS} from "../common/sounds"
import {LatLngTuple} from "leaflet";
import TRAFFIC_PRODUCT_ICONS from "./bvgIcons";

const MOVEMENT_STEPS = 20


const BVGEntity = React.memo(function BVGEntity(props: Entity) {
    const mapContext = useContext(MapContext)
    const entitySonified = useContext(EntitySonified)

    const path = props.data.path.pathCoordinates
    const pathDuration = props.data.path.pathDuration
    const trafficProduct = props.data.trafficProduct
    const volume = props.data.volume

    const [pathSegment, setPathSegment] = useState(0)

    const [position, setPosition] = useState<LatLngTuple>(path[0])
    const [iconPosition, setIconPosition] = useState<[number, number]>([-100, -100])

    const [visible, setVisible] = useState(false)

    const howlerRef = useRef<ReactHowler>(null)


    let tag: string = trafficProduct
    let sounds = BVG_SOUNDS[tag]
    if (!sounds)
        sounds = BVG_SOUNDS["default"]
    const soundFile = sounds[hashFnv32a(props.id) % sounds.length]

    useEffect(() => {
        setPathSegment(0)
        const interval = setInterval(() => {
            setPathSegment(previousPathSegment => previousPathSegment + 1)
        }, pathDuration / path.length)
        return () => clearInterval(interval)
    }, [path, pathDuration])

    // update entity based on position
    useEffect(() => {
        if (pathSegment < path.length) {
            const currentPosition = path[pathSegment]
            if (mapContext.checkInBounds(currentPosition)) {
                setVisible(true)
            } else {
                setVisible(false)
            }
            setPosition(currentPosition)

            if (pathSegment + 1 < path.length) {
                const nextPosition = path[pathSegment + 1]
                const increase = [(nextPosition[0] - currentPosition[0]) / MOVEMENT_STEPS, (nextPosition[1] - currentPosition[1]) / MOVEMENT_STEPS]
                const interval = setInterval(() => {
                    setPosition(previousPosition => [previousPosition[0] + increase[0], previousPosition[1] + increase[1]])
                }, pathDuration / path.length / MOVEMENT_STEPS)
                return () => clearInterval(interval)
            }
        } else {
            setVisible(false)
        }
    }, [path, pathDuration, pathSegment, mapContext])

    useEffect(() => {
        const spatialPosition = mapContext.calculatePositionInBounds(position)
        howlerRef.current?.howler.pos(spatialPosition[0], spatialPosition[1], spatialPosition[2])
        // @ts-ignore
        howlerRef.current?.howler.orientation(0, 0, 1)

        const point = mapContext.calculatePointInBounds(position)
        setIconPosition([point.x, point.y])
    }, [position, mapContext])

    return (
        <>
            <ReactHowler
                src={`sounds/${soundFile.sound.file}.mp3`}
                volume={volume}
                playing={entitySonified && visible}
                ref={howlerRef}
                loop={true}
            />
            {visible ? (
                <img style={{position: 'absolute', width: 22, height: 22, zIndex: 1000, left: iconPosition[0], top: iconPosition[1]}} src={TRAFFIC_PRODUCT_ICONS[trafficProduct]}/>
            ) : null}
        </>
    )
})

export default BVGEntity
