import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import {
    Box, Drawer, Fab, Tab, Tabs, Typography
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ScenarioSelection from './scenarioSelection';
import LocationStore, { LocationStoreProps } from './locationStore';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PieChartIcon from '@mui/icons-material/PieChart';
import TuneIcon from '@mui/icons-material/Tune';
import InfoPanel from './infoPanel';
import {useLocation} from "react-router-dom";

const drawerWidth = 260;

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

interface SideBarProps extends LocationStoreProps {
    scenario: number
    ptFrequency: number
    trafficFrequency: number
    onScenarioUpdate: (scenario: number, ptFrequency: number, trafficFrequency: number) => void
}

const SideBar = React.memo(function SideBar(props: SideBarProps) {

  const searchParams = new URLSearchParams(useLocation().search)
  const hideSideBar = searchParams.get('hideSideBar') ? searchParams.get('hideSideBar') === "true" : false
  const openSideBar:number = searchParams.get('openSideBar') ? parseInt(searchParams.get('openSideBar')!) : 0

  const theme = useTheme();
  const [open, setOpen] = React.useState(openSideBar > 0);

  const toggleDrawerOpen = () => {
     setOpen(!open)
  };


  const [currentTab, setCurrentTab] = React.useState(Math.max(0,openSideBar-1));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  const handleChangeIndex = (newValue: number) => {
    setCurrentTab(newValue);
  };

  
  return ( 
    <Box>   
       <>{hideSideBar ? null : 
        <Fab
            variant="extended"
            color="primary"
            onClick={toggleDrawerOpen}
            sx={{position: 'absolute!important' as any, left: open ? drawerWidth+16 : 16, bottom: 16}}
          >
           {open ? <><ChevronLeftIcon /> Close</> : <><ChevronRightIcon /> More</>}
        </Fab>}</>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            opacity: .5
          },
          '&:hover .MuiDrawer-paper, &:active .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            opacity: 1
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <>
        <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="fullWidth"
            style={{flexShrink: 0}}
        >
            <Tab icon={<PieChartIcon/>} {...a11yProps(0)}/>
            <Tab icon={<TuneIcon/>} {...a11yProps(1)}/>
            <Tab icon={<LocationOnIcon/>} {...a11yProps(2)}/>
        </Tabs>
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={currentTab}
            onChangeIndex={handleChangeIndex}
        >
            <TabPanel value={currentTab} index={0} dir={theme.direction}>
                <InfoPanel/>
            </TabPanel>
            <TabPanel value={currentTab} index={1} dir={theme.direction}>
                <ScenarioSelection
                    scenario={props.scenario}
                    ptFrequency={props.ptFrequency}
                    trafficFrequency={props.trafficFrequency}
                    onScenarioUpdate={props.onScenarioUpdate}/>
            </TabPanel>
            <TabPanel value={currentTab} index={2} dir={theme.direction}>
                <LocationStore 
                    mapBounds={props.mapBounds}
                    mapZoom={props.mapZoom} 
                    navigateToLocation={props.navigateToLocation}
                />
            </TabPanel>
        </SwipeableViews>
        </>      
      </Drawer>
    </Box>
  );
})

export default SideBar

