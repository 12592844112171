import React from "react"

import {CrawlerContext} from "./crawler-context"
import {EntityType, entityTypeComponents} from "./Entities"
import {useLocation} from "react-router-dom"


export interface Entity {
    id: string,
    type: EntityType,
    data: any
}

export interface PlayConfiguration {
    nature: boolean,
    traffic: boolean,
    pt: boolean
}

function EntityComponent(entity: Entity, playConfiguration: PlayConfiguration) {
    const Component = entityTypeComponents[entity.type]
    if(entity.type === EntityType.BVG && !playConfiguration.pt) {
        return null
    }
    if(entity.type === EntityType.Area && !playConfiguration.nature) {
        return null
    }
    if(entity.type === EntityType.TrafficGridCell && !playConfiguration.traffic) {
        return null
    }
    if (Component)
        return <Component key={entity.id} id={entity.id} data={entity.data} type={entity.type}/>
    return null
}

const EntityStore = function EntityStore() {
    let playConfiguration =  {
        nature: false,
        traffic: false,
        pt: false,
    }
    const searchParams = new URLSearchParams(useLocation().search)
    if(searchParams.get("playOnly")) {
        const playOnly = JSON.parse(searchParams.get('playOnly')!!)
        if(playOnly.includes("pt")) {
            playConfiguration["pt"] = true
        }
        if(playOnly.includes("nature")) {
            playConfiguration["nature"] = true
        }
        if(playOnly.includes("traffic")) {
            playConfiguration["traffic"] = true
        }
    } else {
        //play all
        playConfiguration = {
            nature: true,
            traffic: true,
            pt: true,
        }
    }

    return (
        <CrawlerContext.Consumer>
            {({entities}) => (
                <>
                    {Object.entries(entities).map(([_, entity]) => (
                        EntityComponent(entity, playConfiguration)
                    ))}
                </>
            )}
        </CrawlerContext.Consumer>
    )
}

export default EntityStore
