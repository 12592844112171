import React from "react"

import {Entity} from "./EntityStore"
import BVGEntity from "../bvg/BVGEntity"
import AreaEntity from "../osm/AreaEntity"
import RoadEntity from "../osm/RoadEntity";
import TrafficRoadEntity from "../traffic/TrafficRoadEntity";
import TrafficGridCell from "../traffic/TrafficGridCell";


export enum EntityType {
    BVG = 0,
    Area = 100,
    Road = 110,
    TrafficRoad = 200,
    TrafficGridCell = 300
}

export const entityTypeComponents: { [key in EntityType]?: React.ComponentType<Entity> } = {
    [EntityType.BVG]: BVGEntity,
    [EntityType.Area]: AreaEntity,
    //[EntityType.Road]: RoadEntity,
    //[EntityType.TrafficRoad]: TrafficRoadEntity,
    [EntityType.TrafficGridCell]: TrafficGridCell
}
