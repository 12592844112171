import { Divider, Link, Typography } from "@mui/material";
import SoundChart from "./soundChart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TuneIcon from '@mui/icons-material/Tune';

export default function InfoPanel() {
    return (
        <>
        <Typography variant="h5">Soundscape</Typography>
        <SoundChart/>
        <Divider sx={{marginTop: 1, marginBottom: 1}} />
        <Typography variant="body1">
            You can choose between different scenarios in the <TuneIcon sx={{verticalAlign: 'middle'}}/> tab and save and access your favorite locations in the <LocationOnIcon  sx={{verticalAlign: 'middle'}}/> tab.
        </Typography>
        <Typography variant="body1">
            Also checkout our <Link href="https://birds-ear.de/story" target="_blank" rel="noreferrer">Bird's Ear Story</Link>.
        </Typography>
        </>
    )
}
