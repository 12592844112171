import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, IconButton, List, ListItem, ListItemButton, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaceIcon from '@mui/icons-material/Place';
import React, { useEffect } from "react";
import {LatLng, LatLngBounds} from "leaflet";

const DENSE = true

interface Location {
  center: LatLng,
  zoom: number,
  name: string
}

export interface LocationStoreProps {
  mapBounds: LatLngBounds | undefined
  mapZoom: number | undefined

  navigateToLocation: (center: LatLng, zoom: number) => void
}

export default function LocationStore(props: LocationStoreProps) {

  const [isAddLocationDialogOpen, setIsAddLocationDialogOpen] = React.useState(false)  
  const [locationName, setLocationName] = React.useState("")
  const [locations, setLocations] = React.useState<Location[]>([])

  useEffect(() => {
      const storedLocations = localStorage.getItem("locations")
      setLocations(storedLocations ? JSON.parse(storedLocations) : [])
  }, [])

  const toggleAddLocationDialog = () => {
    setIsAddLocationDialogOpen(!isAddLocationDialogOpen)
}
const saveLocation = () => {
  setIsAddLocationDialogOpen(false)
  const newLocation = {
      center: props.mapBounds!!.getCenter(),
      zoom: props.mapZoom!!,
      name: locationName
  }
  localStorage.setItem("locations", JSON.stringify([...locations, newLocation]))
  setLocations([...locations, newLocation])
}
const deleteLocation = (location: Location) => {
  setLocations(locations.filter((storedLocation) => location.center !== storedLocation.center))
}

const navigateLocation = (location: Location) => {
  props.navigateToLocation(location.center, location.zoom)
}

    return (
        <>
        <Typography variant="h5">Saved Locations</Typography>   
        <List dense={DENSE}>
          <ListItem disablePadding
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={toggleAddLocationDialog}>
                  <PlaceIcon />
                </IconButton>
              }
            >
            <ListItemButton onClick={toggleAddLocationDialog}> Save This Location</ListItemButton>
            </ListItem>
          {locations.map((loc, index) => (
            <ListItem disablePadding
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => deleteLocation(loc)}>
                  <DeleteForeverIcon />
                </IconButton>
              }
            >
            <ListItemButton onClick={() => navigateLocation(loc)}> {loc.name}</ListItemButton>
            </ListItem>
          ))}
        </List>
        <Dialog open={isAddLocationDialogOpen}
                    onClose={toggleAddLocationDialog}>
                <DialogTitle> Add Location </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please name the location to be added.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Location Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={locationName}
                        onChange={(e) => setLocationName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleAddLocationDialog}>Cancel</Button>
                    <Button onClick={saveLocation}>Add Location</Button>
                </DialogActions>
            </Dialog>
        </>
    );
    }