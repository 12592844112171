import {Select, MenuItem, Typography, Slider, SelectChangeEvent} from "@mui/material";
import React, {useEffect} from "react";

interface ScenarioSelectionProps {
    scenario: number
    ptFrequency: number
    trafficFrequency: number
    onScenarioUpdate: (scenario: number, ptFrequency: number, trafficFrequency: number) => void
}

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 10 / 3,
    },
    {
        value: 25 / 3,
    },
    {
        value: 50 / 3,
    },
    {
        value: 100/3,
        label: '100%',
    },
    {
        value: 200 / 3,
        label: '200%',
    },
    {
        value: 300 / 3,
        label: '300%',
    }
]

function valuetext(value: number) {
    return `${value}%`
}

function valueLabelFormat(value: number) {
    return valuetext(Math.round(value * 3))
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const scenarios = [
    "Actual Soundscape",
    "Car-free city",
    "More individual traffic"
]

const ScenarioSelection = React.memo(function ScenarioSelection(props: ScenarioSelectionProps) {
    const doScenarioChange = (scenario: number) => {
        if(scenario === 1) {
            props.onScenarioUpdate(scenario, 100, 100)
        } else if(scenario === 2) {
            props.onScenarioUpdate(scenario, 300, 10)
        } else if(scenario === 3) {
            props.onScenarioUpdate(scenario, 100, 300)
        } else {
            props.onScenarioUpdate(0, props.ptFrequency, props.trafficFrequency)
        }
    }

    const handleScenarioChange = (event: SelectChangeEvent) => {
        doScenarioChange(event.target.value === "" ? 0 : parseInt(event.target.value))
    };

    const handleTrafficChange = (event: Event, newValue: number | number[]) => {
        props.onScenarioUpdate(0, props.ptFrequency, Math.round(newValue as number * 3))
    }

    const handlePTChange = (event: Event, newValue: number | number[]) => {
        props.onScenarioUpdate(0, Math.round(newValue as number * 3), props.trafficFrequency)
    }

    return (
        <>
            <Typography variant="h5">Simulations</Typography>
            <p>Besides listening to a simulation of the current soundscape, we can also showcase different scenarios. This way, one can listen to a potential future version of the city.</p>

            <Typography variant="h6" sx={{marginBottom: 1}}>Choose Scenario:</Typography>
            <Select value={props.scenario == 0 ? "" : props.scenario.toString()} onChange={handleScenarioChange}
                    displayEmpty
                    renderValue={(selected) => {
                        if (selected == "") {
                            return <em>Custom</em>;
                        }

                        return scenarios[selected as unknown as number - 1]
                    }}

            MenuProps={MenuProps}
            sx={{width: "100%", marginBottom: 2}}>
                <MenuItem value={1}>Actual Soundscape</MenuItem>
                <MenuItem value={2}>Car-free city</MenuItem>
                <MenuItem value={3}>More individual traffic</MenuItem>
                <MenuItem value={4} disabled>To be continued ...</MenuItem>
            </Select>

            <Typography variant="subtitle1" sx={{textAlign: 'center'}}>TRAFFIC</Typography>
            <Slider
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                step={null}
                valueLabelDisplay="on"
                marks={marks}
                sx={{width: '95%', marginTop: 5}}
                value={props.trafficFrequency / 3}
                onChange={handleTrafficChange}
            />
            <Typography variant="caption">100% equals current amount of traffic. Increasing and decreasing scales road and current traffic flow data.</Typography>

            <Typography  variant="subtitle1" sx={{marginTop: 2, textAlign: 'center'}}>PUBLIC TRANSPORT</Typography>
            <Slider
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                step={null}
                valueLabelDisplay="on"
                marks={marks}
                sx={{width: '95%', marginTop: 5}}
                value={props.ptFrequency / 3}
                onChange={handlePTChange}
            />
            <Typography variant="caption">100% equals all public transport currently in this area. Increasing and decreasing either removes rides or adds additional rides from a few minutes ago.</Typography>
        </>
    );
})

export default ScenarioSelection
