/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @returns {number}
 */
 export function hashFnv32a(str?: string): number {
    if (!str)
       return 0;
   var i, l,
       hval = 0x811c9dc5;
   for (i = 0, l = str.length; i < l; i++) {
       hval ^= str.charCodeAt(i);
       hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
   }
   return hval >>> 0;
}


export enum ROAD_TYPE {MOTORWAY, TERTIARY, ROAD, RESIDENTIAL}

export type RoadInfo = {
    type: ROAD_TYPE
    length: number
    jamMin: number
    jamMax: number
    jamAvg: number
    jamInfoLength: number
    blockedLength: number

    volume: number
    level: string
}

export interface NatureInformation {
    volume: number
    data: {
        forestArea: number
        parkArea: number
        meadowArea: number
        lakeArea: number
        riverLargeArea: number
        riverSmallArea: number
    }
}
  
  export interface TrafficInformation {
    volume: number
    data: {
        motorwayInfo: RoadInfo //we ignore level for now
        tertiaryInfo: RoadInfo 
        roadInfo: RoadInfo
        residentialInfo: RoadInfo
    }
  }
  export interface PtInformation {
    volume: number
    data: {
        busses: number
        ferries: number
        trams: number
        express: number
        regionals: number
        suburbans: number
    }
  }

export interface GroupStats {
    nature?: NatureInformation|undefined
    traffic?: TrafficInformation|undefined
    pt?: PtInformation|undefined
}