import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Backdrop, Box,
    Divider,
    Fab, Fade, 
    IconButton,
    Link,
    Modal, Typography
} from "@mui/material"
import {Close, Help,} from "@material-ui/icons"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from "@mui/icons-material/Search";
import PlayIcon from "@mui/icons-material/PlayArrow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            position: 'relative'
        },
        closeButton: {
            position: 'absolute',
            right: 5,
            top: 5,
            color: theme.palette.grey[500],
        },
        inlineIconCentered: {
            verticalAlign: 'middle'
        }
    }),
)

export default function Tutorial() {
    const classes = useStyles()

    const [open, setOpen] = React.useState(true)
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
       
            <Fab onClick={handleOpen}
                 sx={{position: 'absolute' as any, right: 16, bottom: 16}}>
                <Help />
            </Fab>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                            <Close />
                        </IconButton>
                        <Box maxWidth={900}>
                            <Typography variant="h4">
                                Welcome! 
                            </Typography>
                            <Typography variant="subtitle1">
                                Bird's Ear is a live application that simulates the soundscapes of urban and rural areas. Interested in what that means and how it works? Have a look into our  <Link href="https://birds-ear.de/story" target="_blank" rel="noreferrer">Bird's Ear Story</Link>.
                            </Typography>
                            <Typography variant="h5">
                                How do I use it?
                            </Typography>
                            <Typography variant="subtitle1">
                            Navigate along the map by dragging and scroll to zoom in and out. As soon as you settle on a section of the map, you can start a simulated soundscape for the area you are looking at using real-time data. To do so, click the
                                <Fab color="primary"
                                     size="small"
                                     sx={{position: 'relative!important' as any, marginRight: 0.5, marginLeft: 0.5}}>
                                    <PlayIcon/>
                                </Fab> button.
                            Experience the soundscape of a whole quarter at once and compare the soundscapes of different places!
                            </Typography>

                            <Typography variant="subtitle1">
                                Use <Fab
                                size="small"
                                variant="extended"
                                color="primary"
                                sx={{position: 'relative!important' as any, display: 'inline-flex'}}
                            >
                                <><ChevronRightIcon /> More</>
                            </Fab> to access numerical information on what you are listening to, selecting various scenarios, and to save and access your favorite locations easily.
                                Click <Fab size="small" sx={{position: 'relative!important' as any}}>
                                {<SearchIcon/>}
                            </Fab> in the topright corner to find places easily.
                            </Typography>
                            <Typography variant="subtitle1">
                                So far, our tool only supports the area in and around Berlin.
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <Divider />
                        </Box>
                        <Box my={1}>
                            <Typography variant="subtitle2">Vincent Rahn, Simon Wietheger / 2021&ndash;2022</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="subtitle2">Built with&nbsp;
                                <Link href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                    React
                                </Link>,&nbsp;
                                <Link href="https://nodejs.org/" target="_blank" rel="noreferrer">
                                    Node.js
                                </Link>,&nbsp;
                                <Link href="https://expressjs.com/" target="_blank" rel="noreferrer">
                                    Express
                                </Link>,&nbsp;
                                <Link href="https://howlerjs.com/" target="_blank" rel="noreferrer">
                                    Howler.js
                                </Link>,&nbsp;
                                <Link href="https://www.geoapify.com/" target="_blank" rel="noreferrer">
                                    Geoapify
                                </Link>,&nbsp;
                                <Link href="https://developer.here.com/" target="_blank" rel="noreferrer">
                                    here
                                </Link>,&nbsp;
                                <Link href="https://github.com/public-transport/hafas-client" target="_blank" rel="noreferrer">
                                    hafas-client
                                </Link>, and&nbsp;
                                <Link href="https://www.openstreetmap.org" target="_blank" rel="noreferrer">OpenStreetMap
                                </Link>.
                                </Typography>
                                <Typography variant="subtitle2">
                                All of the sound you can hear in our application stems from recordings of the openly available&nbsp;
                                <Link href="https://sound-effects.bbcrewind.co.uk/" target="_blank" rel="noreferrer">BBC sound library
                                </Link>.
                                </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5">Listen to the world like a bird would!</Typography>
                        </Box>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
