import React, {forwardRef, useImperativeHandle, useRef} from "react"

import {LatLngBounds, LatLngBoundsExpression, LatLngExpression, LatLngTuple, Point} from "leaflet"
import {MapContainer, TileLayer, useMapEvent, ZoomControl} from 'react-leaflet'

import EntityStore from "../common/EntityStore"
import {MapContext} from "../common/crawler-context"
import {MAP_CENTER, MAP_ZOOM, MIN_ZOOM, MAX_ZOOM, MAX_BOUNDS} from "../common/constants";
import {useLocation} from "react-router-dom";

export type MapRef = {
    flyTo: (center: LatLngExpression, zoom: number) => void
    flyToBounds: (bounds: LatLngBoundsExpression) => void
} | null;

export interface MapProps {
    onBoundsUpdate: (bounds: LatLngBounds, zoom: number) => void
}

export interface MapBoundsCheckerProps {
    onBoundsUpdate: (bounds: LatLngBounds, zoom: number) => void
}

function MapBoundsChecker(props: MapBoundsCheckerProps) {
    const map = useMapEvent('moveend', () => {
        props.onBoundsUpdate(map.getBounds(), map.getZoom())
    })
    return null
}

export type SpatialTuple = [number, number, number]

const Map = forwardRef((props: MapProps, ref) => {
    let mapRef = useRef<L.Map>()

    const [bounds, setBounds] = React.useState<LatLngBounds>()

    let startCenter = MAP_CENTER
    let startZoom = MAP_ZOOM

    const searchParams = new URLSearchParams(useLocation().search)
    const hideZoomControl = searchParams.get('hideZoomControl') ? searchParams.get('hideZoomControl') === "true" : false
    const isIFrame = searchParams.get('isIFrame') ? searchParams.get('isIFrame') === "true" : false
    if(searchParams.get('center')) {
        startCenter = JSON.parse(searchParams.get('center')!!)
    }
    if(searchParams.get('zoom')) {
        startZoom = parseInt(searchParams.get('zoom')!!)
    }

    const onBoundsUpdate = (bounds: LatLngBounds, zoom: number) => {
        props.onBoundsUpdate(bounds, zoom)
        setBounds(bounds)
    }

    const checkInBounds = (point: LatLngTuple): boolean => {
        return bounds?.contains(point) ?? false
    }

    const calculatePositionInBounds = (point: LatLngTuple): SpatialTuple => {
        const z = -1 // 0.01 * 100

        if (bounds) {
            const x = (point[1] - bounds.getCenter().lng) * 100
            const y = (point[0] - bounds.getCenter().lat) * 100
            return [x, y, z]
        }
        return [0, 0, z]
    }

    const calculatePointInBounds = (point: LatLngTuple): Point => {
        if (bounds && mapRef.current) {
            return mapRef.current.latLngToLayerPoint(point)
        }
        return new Point(-1000, -1000)
    }

    useImperativeHandle(ref, () => ({
        flyTo: (center: LatLngExpression, zoom: number) => {
            mapRef.current?.flyTo(center, zoom, {duration: 2})
        },
        flyToBounds: (bounds: LatLngBoundsExpression) => {
            mapRef.current?.flyToBounds(bounds, {duration: 2})
        }
    }))

    return (
        <MapContainer
            whenCreated={(mapInstance) => {
                mapInstance.fitBounds(new LatLngBounds([52.521643, 13.398167], [52.52183232326953, 13.414625409920232]  ))
                mapInstance.setMinZoom(mapInstance.getZoom())
                mapInstance.setView(startCenter, startZoom)
                mapRef.current = mapInstance
                onBoundsUpdate(mapInstance.getBounds(), mapInstance.getZoom())

            }}
            center={startCenter} zoom={startZoom} zoomSnap={0.5} zoomDelta={0.5} maxZoom={MAX_ZOOM} minZoom={MIN_ZOOM} maxBounds={MAX_BOUNDS} scrollWheelZoom={false} doubleClickZoom={false}
            trackResize={true} zoomControl={false} dragging={!isIFrame}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url={"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png"}
            />
            {hideZoomControl ? null : <ZoomControl position="topright" />}
            <MapBoundsChecker onBoundsUpdate={onBoundsUpdate}/>
            <MapContext.Provider value={{
                checkInBounds: checkInBounds,
                calculatePositionInBounds: calculatePositionInBounds,
                calculateAreaCoverageInBounds: (size) => 0,
                calculateMetricAreaCoverageInBounds: (size) => 0,
                calculatePointInBounds: calculatePointInBounds,
                bounds: bounds

            }}>
                <EntityStore/>
            </MapContext.Provider>
        </MapContainer>
    )
})

export default Map
