const TRAFFIC_PRODUCT_ICONS : Record<string, string> = {
    bus: 'media/bus.gif',
    subway: 'media/ubahn.gif',
    suburban: 'media/sbahn.png',
    regional: 'media/bahn_gro.gif',
    express: 'media/fernverkehr.png',
    tram: 'media/tram.gif',
    ferry: 'media/fahre.gif'
}

export default TRAFFIC_PRODUCT_ICONS
