import {LatLngBounds, LatLngTuple} from "leaflet"

// const COORD_BERLIN_HBF: LatLngTuple =  [52.525240541323384, 13.369584388695555]
// const COORD_HPI: LatLngTuple =  [52.39415822553194, 13.132570475856328]
// const COORD_KREUZBERG: LatLngTuple = [52.50228615519147, 13.442636757607445]
const COORD_SIEMENSDAMM: LatLngTuple = [52.533388049767396, 13.289590912630638]

export const MAP_CENTER = COORD_SIEMENSDAMM
export const MAP_ZOOM : number = 16.5
export const MAX_ZOOM : number = 18
export const MIN_ZOOM : number = 16
export const MAX_BOUNDS : LatLngBounds = new LatLngBounds([[52.3418234221, 13.0882097323], [52.6697240587, 13.7606105539]])

export const ALL_MUTED = false
export const OVERLAYS_ON = false
