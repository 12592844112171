import React from "react"
import TrafficSoundElement from "./TrafficSoundElement"
import {SpatialTuple} from "../map/Map";
import {ROAD_TYPE, RoadInfo} from "../common/utils";

interface TrafficSoundCompositionProps {
    spatialPosition: SpatialTuple
    area: number
    roadTypeInfos: Record<ROAD_TYPE, RoadInfo>
    playing: boolean
}


const TrafficSoundComposition = React.memo(function TrafficSoundComposition(props: TrafficSoundCompositionProps) {
    return (
        <>
            {props.roadTypeInfos[ROAD_TYPE.MOTORWAY].length > 0 ?
                <TrafficSoundElement key={ROAD_TYPE.MOTORWAY.valueOf()} info={props.roadTypeInfos[ROAD_TYPE.MOTORWAY]}
                                     playing={props.playing} spatialPosition={props.spatialPosition}
                                     areaSize={props.area}/> : null}
            {props.roadTypeInfos[ROAD_TYPE.TERTIARY].length > 0 ?
                <TrafficSoundElement key={ROAD_TYPE.TERTIARY.valueOf()} info={props.roadTypeInfos[ROAD_TYPE.TERTIARY]}
                                     playing={props.playing} spatialPosition={props.spatialPosition}
                                     areaSize={props.area}/> : null}
            {props.roadTypeInfos[ROAD_TYPE.ROAD].length > 0 ?
                <TrafficSoundElement key={ROAD_TYPE.ROAD.valueOf()} info={props.roadTypeInfos[ROAD_TYPE.ROAD]}
                                     playing={props.playing} spatialPosition={props.spatialPosition} 
                                     areaSize={props.area}/> : null}
            {props.roadTypeInfos[ROAD_TYPE.RESIDENTIAL].length > 0 ?
                <TrafficSoundElement key={ROAD_TYPE.RESIDENTIAL.valueOf()} info={props.roadTypeInfos[ROAD_TYPE.RESIDENTIAL]}
                                     playing={props.playing} spatialPosition={props.spatialPosition}
                                     areaSize={props.area}/> : null}
        </>
    )
})

export default TrafficSoundComposition