import React, {useRef} from "react"
import ReactHowler from "react-howler"
import {RoadInfo} from "../common/utils";
import {TRAFFIC_SOUNDS} from "../common/sounds";
import {SpatialTuple} from "../map/Map";

interface TrafficSoundElementProps {
    info: RoadInfo
    playing: boolean
    spatialPosition: SpatialTuple
    areaSize: number
}



const TrafficSoundElement = React.memo(function TrafficSoundElement(props: TrafficSoundElementProps) {
    const howlerRef = useRef<ReactHowler>(null)

    const volume = props.info.volume
    const level = props.info.level

    const soundFile = () => {
        let sounds = TRAFFIC_SOUNDS[props.info.type]
        let soundsLevelFiltered = sounds.filter((soundFile) => soundFile.tags["level"] === level)
        if (soundsLevelFiltered.length > 0) sounds = soundsLevelFiltered

        return sounds[Math.floor(Math.random() * sounds.length)]
    }

    howlerRef.current?.howler.pos(props.spatialPosition[0], props.spatialPosition[1], props.spatialPosition[2])
    // @ts-ignore // wrong typing here in howler-react
    howlerRef.current?.howler.orientation(0, 0, 1)

    return (
        <>
            <ReactHowler
                src={`sounds/${soundFile().sound.file}.mp3`}
                volume={volume}
                playing={props.playing}
                ref={howlerRef}
                loop={true}
            />
        </>
    )
})

export default TrafficSoundElement